import Layout from "../components/sell/Layout";

const PageNotFound = () => (
  <div>
    <h1>Page Not Found</h1>
  </div>
);

PageNotFound.getLayout = function getLayout(index) {
  return (
    <>
      <Layout title="Relove">{index}</Layout>
    </>
  );
};

export default PageNotFound;
